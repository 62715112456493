import type { PropsWithChildren } from 'react';
import React from 'react';

export function FormFieldErrorMessage({
  children,
}: Readonly<PropsWithChildren>): JSX.Element {
  return (
    <div className="label">
      <span className="label-text-alt text-red-500">{children}</span>
    </div>
  );
}
