import React from 'react';

import logo from './logo.svg';

export function OldApp() {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <img src={logo} className="animate-pulse w-full max-w-md" alt="logo" />
      <p>Nothing to see here, carry on...</p>
    </div>
  );
}
