import { ErrorMessage } from 'formik';
import type { PropsWithChildren } from 'react';
import React from 'react';

import type { FormFieldConfig } from '../../../types/Model';

import { FormFieldErrorMessage } from './FormFieldErrorMessage';

export type FormFieldWrapperProps = Readonly<
  PropsWithChildren & {
    config: FormFieldConfig;
  }
>;

function getColSpan(config: FormFieldConfig): string {
  switch (config.type) {
    case 'number':
      return 'col-span-2';

    case 'image':
    case 'select':
    case 'string':
      return 'col-span-3';

    default:
      // eslint-disable-next-line no-console
      console.error('Unsupported field!', config satisfies never);
      throw new Error('Unsupported field!');
  }
}

export function FormFieldWrapper({
  children,
  config,
}: FormFieldWrapperProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`${getColSpan(config)} form-control`}>
      <div className="label">
        <span className="label-text">{config.label}</span>
      </div>

      {children}

      <ErrorMessage name={config.key} component={FormFieldErrorMessage} />
    </label>
  );
}
