import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageGroupsScreen } from '../../components/Images/ImageGroupsScreen';
import { ImageListScreen } from '../../components/Images/ImageListScreen';
import type { ImageMode } from '../../components/Images/ImageModeTabs';
import { ImageModeTabs } from '../../components/Images/ImageModeTabs';
import { ImageUploadScreen } from '../../components/Images/ImageUploadScreen';

export function ImageManagerPage(): JSX.Element {
  const { group } = useParams();

  const navigate = useNavigate();

  const [mode, setMode] = useState<ImageMode>(group ? 'images' : 'groups');

  const setGroup = (value: string) => {
    navigate(`/image-manager/${value}`);
    setMode('images');
  };

  return (
    <section className="p-4 space-y-4">
      <ImageModeTabs
        group={group}
        groupSelection
        mode={mode}
        onSelect={setMode}
      />

      {mode === 'groups' && (
        <ImageGroupsScreen group={group} onSelect={setGroup} />
      )}

      {mode === 'images' && <ImageListScreen group={group!} />}

      {mode === 'upload' && (
        <ImageUploadScreen group={group!} onFinish={() => setMode('images')} />
      )}
    </section>
  );
}
