/* eslint-disable jsx-a11y/label-has-associated-control */
import type { PropsWithChildren } from 'react';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LogOutIcon } from '../../icons/log-out.svg';
import { ReactComponent as MoonIcon } from '../../icons/moon.svg';
import { ReactComponent as SunIcon } from '../../icons/sun.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import type { Model } from '../../types/Model';
import { Logo } from '../components/Logo';
import { ModelsContext } from '../context/models';
import { ThemeContext } from '../context/theme';
import { UserNameContext } from '../context/userName';
import { getAPIClient } from '../utils/getAPIClient';

function MenuItem({ name, slug }: Readonly<Model>) {
  return (
    <li role="menuitem" key={slug}>
      <NavLink to={`/${slug}`}>{name}</NavLink>
    </li>
  );
}

function Side() {
  const data = useContext(ModelsContext);
  const userName = useContext(UserNameContext);

  const { theme, setTheme } = useContext(ThemeContext);

  const useDarkTheme = () => setTheme('dark');
  const useLightTheme = () => setTheme('light');

  const logout = () => {
    getAPIClient()
      .post('/ui/logout')
      .then(() => window.location.reload());
  };

  return (
    <section className="min-h-full w-80 flex flex-col bg-base-300 text-base-content">
      <NavLink to="/" className="sticky top-0 z-10">
        <nav role="navigation" className="navbar bg-[#4de898]">
          <Logo />
        </nav>
      </NavLink>

      <ul role="menu" className="menu p-4 grow">
        {data.map(MenuItem)}

        <div className="grow" />

        <li role="menuitem">
          <NavLink to="/image-manager">Image Manager</NavLink>
        </li>
      </ul>

      <footer className="sticky bottom-0 z-10 bg-[#4de898] text-[#1D3849] flex items-center">
        <div className="pl-4">
          <UserIcon width={20} />
        </div>

        <span className="flex-1 px-1 font-medium">{userName}</span>

        <button
          type="button"
          className="btn btn-ghost btn-square rounded-none"
          onClick={logout}
          aria-label="Log Out"
        >
          <LogOutIcon width={20} />
        </button>

        {theme === 'light' && (
          <button
            type="button"
            className="btn btn-ghost btn-square rounded-none"
            onClick={useDarkTheme}
            aria-label="Use Dark Theme"
          >
            <MoonIcon width={20} />
          </button>
        )}

        {theme === 'dark' && (
          <button
            type="button"
            className="btn btn-ghost btn-square rounded-none"
            onClick={useLightTheme}
            aria-label="Use Light Theme"
          >
            <SunIcon width={20} />
          </button>
        )}
      </footer>
    </section>
  );
}

export function LayoutDrawer({ children }: PropsWithChildren) {
  return (
    <div className="drawer lg:drawer-open">
      <input id="theme-drawer" type="checkbox" className="drawer-toggle" />

      <div className="drawer-content bg-base-200 flex flex-col">{children}</div>

      <div className="drawer-side">
        <label
          htmlFor="theme-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        />

        <Side />
      </div>
    </div>
  );
}
