import type { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { getAPIClient } from './getAPIClient';

export type AdminAPIHook<T> = {
  data?: T;
  error?: AxiosError;
  loading: boolean;

  reload(): Promise<void>;
};

export type GenericEntity = Record<string, any>;

export type GenericCollection = GenericEntity[];

export function useAdminAPIGet<T>(path: string): AdminAPIHook<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<T>();

  async function makeRequest() {
    setLoading(true);

    setError(undefined);
    setData(undefined);

    try {
      const response = await getAPIClient().get(path);

      setData(response.data);
    } catch (e) {
      setError(e as AxiosError);
    }

    setLoading(false);
  }

  useEffect(() => {
    makeRequest();
  }, [path]);

  return {
    data,
    error,
    loading,

    reload() {
      return makeRequest();
    },
  };
}
