import type { AxiosError } from 'axios';

import type { ImageGroup } from '../../types/Images';

import { useAdminAPIGet } from './useAdminAPIGet';

export type ImageGroupsHook = {
  loading: boolean;
  error?: AxiosError;

  groups?: ImageGroup[];

  getGroupLabel(slog: string): string;
};

export type GenericEntity = Record<string, any>;

export type GenericCollection = GenericEntity[];

export function useImageGroups(): ImageGroupsHook {
  const { loading, error, data } =
    useAdminAPIGet<ImageGroup[]>('/ui/image-groups');

  return {
    loading,
    error,

    groups: data,

    getGroupLabel(slug) {
      const group = data?.find(g => g.slug === slug);

      return group?.label ?? '';
    },
  };
}
