import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useMemo, useState } from 'react';

import type { User } from '../types/User';

import { LoadingSpinner } from './components/LoadingSpinner';
import { Router } from './components/Router';
import { ThemeContext } from './context/theme';
import { UserNameContext } from './context/userName';
import { AccountLoginPage } from './pages/account/loginPage';
import { useAdminAPIGet } from './utils/useAdminAPIGet';
import { useTheme } from './utils/useTheme';

export function App() {
  const { data, error, loading } = useAdminAPIGet<User>('/ui/me');

  const [user, setUser] = useState<User>();

  const { theme, setTheme } = useTheme();
  const themeProviderValue = useMemo(
    () => ({ theme, setTheme }),
    [theme, setTheme]
  );

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  if (!data && !user && error) {
    if (error.response?.status === 401) {
      return (
        <GoogleOAuthProvider clientId="676776488925-pt71qfe5gc3alqqahebg63gebot1rji0.apps.googleusercontent.com">
          <AccountLoginPage setUser={setUser} />
        </GoogleOAuthProvider>
      );
    }

    throw error;
  }

  return (
    <main data-theme={theme}>
      <ThemeContext.Provider value={themeProviderValue}>
        <UserNameContext.Provider value={data?.name ?? user?.name ?? ''}>
          <Router />
        </UserNameContext.Provider>
      </ThemeContext.Provider>
    </main>
  );
}
