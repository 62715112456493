import { useEffect, useState } from 'react';

type Theme = 'dark' | 'light';

export type ThemeHook = {
  theme: Theme;
  setTheme(theme: Theme): void;
};

export function useTheme(): ThemeHook {
  const [theme, setTheme] = useState<Theme>('light');

  const updateTheme = ({ matches }: { matches: boolean }) => {
    setTheme(matches ? 'dark' : 'light');
  };

  if (window.matchMedia) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    const checkTheme = () => {
      updateTheme(prefersDark);

      prefersDark.addEventListener('change', updateTheme);
    };

    useEffect(checkTheme, []);
  }

  return { theme, setTheme };
}
