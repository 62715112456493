import type { PropsWithChildren } from 'react';
import React from 'react';

type ImageGridWrapperProps = Readonly<{
  className?: string;
}>;

export function ImageGridWrapper({
  children,
  className,
}: ImageGridWrapperProps & PropsWithChildren): JSX.Element {
  return (
    <div
      className={`grid gap-4 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 ${className}`}
    >
      {children}
    </div>
  );
}
