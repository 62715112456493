/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as TrashIcon } from '../../../icons/trash-2.svg';
import type { Model } from '../../../types/Model';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { getAPIClient } from '../../utils/getAPIClient';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';

type DeletePageProps = Readonly<{
  model: Model;
}>;

export function DeletePage({ model }: DeletePageProps) {
  const { id } = useParams();

  const { loading } = useAdminAPIGet<Record<string, string>>(
    `${model.slug}/${id}`
  );

  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);

  const doDelete = async () => {
    setDeleting(true);

    await getAPIClient()
      .delete(`${model.slug}/${id}`)
      .then(() => navigate(`/${model.slug}`));

    setDeleting(false);
  };

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  return (
    <section className="p-4">
      <div className="card card-compact w-full bg-base-100 shadow-sm">
        <div className="card-body gap-4">
          <span className="text-base">
            Are you sure mate? This action cannot be reversed.
          </span>

          <div className="flex">
            <div className="flex-grow" />

            <button
              className="btn btn-error btn-sm items-center"
              type="button"
              disabled={loading || deleting}
              onClick={doDelete}
            >
              <TrashIcon width={16} />

              <span className="flex-grow join-item text-left">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
