/* eslint-disable react/jsx-props-no-spreading */
import type { FormikHelpers } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import type { ObjectSchema } from 'yup';

import { ReactComponent as SaveIcon } from '../../../icons/save.svg';
import type { FormFieldConfig } from '../../../types/Model';
import { FormField } from '../../components/forms/FormField';

type FormProps<Values extends Record<string, unknown>> = Readonly<{
  fields: FormFieldConfig[];

  values: Values;
  schema: ObjectSchema<Values>;

  onChange(values: Values): void | Promise<unknown>;

  onSubmit(
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ): void | Promise<unknown>;
}>;

export function Form<Values extends Record<string, unknown>>({
  fields,
  values,
  schema,
  onChange,
  onSubmit,
}: FormProps<Values>) {
  const [submitIsPossible, setSubmitIsPossible] = useState(false);

  const formik = useFormik({
    initialValues: values,
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    if (formik.isSubmitting || !formik.isValid) {
      setSubmitIsPossible(false);
    } else {
      setSubmitIsPossible(
        Object.keys(formik.initialValues).some(
          key => formik.values[key] !== formik.initialValues[key]
        )
      );
    }
  }, [
    formik.isSubmitting,
    formik.isValid,
    formik.initialValues,
    formik.values,
  ]);

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit} className="grid grid-cols-6 gap-4">
      <FormikProvider value={formik}>
        {fields.map(config => (
          <FormField
            key={config.key}
            config={config}
            internal={!!values?.internal}
          />
        ))}
      </FormikProvider>

      <div className="flex col-span-6">
        <div className="flex-grow" />

        <Button
          type="submit"
          color="primary"
          size="sm"
          disabled={!submitIsPossible}
        >
          <SaveIcon width={16} />
          Save
        </Button>
      </div>
    </form>
  );
}
