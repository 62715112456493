/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { ReactComponent as MenuIcon } from '../../icons/menu.svg';

type LayoutNavbarProps = {
  title: string;
};

export function LayoutNavbar({ title }: LayoutNavbarProps) {
  return (
    <nav role="navigation" className="navbar gap-4 px-4 bg-base-300">
      <div className="flex-none lg:hidden">
        <label
          htmlFor="theme-drawer"
          className="btn btn-ghost btn-square drawer-button"
        >
          <MenuIcon />
        </label>
      </div>

      <div className="flex-1">{title}</div>
    </nav>
  );
}
