import { Field } from 'formik';
import React from 'react';

import { useAdminAPIGet } from '../../../../utils/useAdminAPIGet';

import type { Option } from './StaticOptionsSelect';
import { StaticOptionsSelect } from './StaticOptionsSelect';

type RemoteOptionsSelectProps = Readonly<{
  name: string;

  optionsUrl: string;

  disabled?: boolean;
}>;

export function RemoteOptionsSelect({
  name,
  optionsUrl,
  disabled,
}: RemoteOptionsSelectProps): JSX.Element {
  const { loading, data } = useAdminAPIGet<Option[]>(optionsUrl);

  if (loading) {
    return (
      <Field
        component="select"
        className="select select-bordered select-sm"
        disabled
      >
        <option>Loading...</option>
      </Field>
    );
  }

  if (!data) {
    return (
      <Field
        component="select"
        className="select select-bordered select-sm"
        disabled
      >
        <option>Failed to load options!</option>
      </Field>
    );
  }

  return <StaticOptionsSelect name={name} disabled={disabled} options={data} />;
}
