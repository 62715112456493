import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as RefreshIcon } from '../../../icons/refresh-cw.svg';
import type { FormPreviewConfig } from '../../../types/Model';
import { useAdminAPIPost } from '../../utils/useAdminAPIPost';

import { SidebarPreviewLine } from './SidebarPreviewLine';

type SidebarPreviewCardProps = Readonly<{
  baseUrl: string;

  formValues: Record<string, unknown>;

  preview: NonNullable<FormPreviewConfig>;
}>;

export function SidebarPreviewCard({
  baseUrl,
  formValues,
  preview,
}: SidebarPreviewCardProps): JSX.Element {
  const [refreshQueued, setRefreshQueued] = useState(false);

  const { loading, reload, data, error } = useAdminAPIPost<
    Record<string, unknown>
  >(`${baseUrl}/preview`, formValues);

  const timerRef = useRef(setTimeout(() => {}, 0));

  const refresh = () => {
    reload(formValues);

    setRefreshQueued(false);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setRefreshQueued(true);

    timerRef.current = setTimeout(refresh, 2000);
  }, [formValues]);

  return (
    <div className="card card-bordered card-compact bg-base-100 shadow-sm">
      <div className="card-body space-y-2">
        <div className="flex justify-between">
          <span className="font-bold">Preview</span>

          <button
            type="button"
            aria-label="refresh"
            className="btn btn-xs btn-ghost -my-1"
            disabled={refreshQueued || loading}
            onClick={refresh}
          >
            {(refreshQueued || loading) && (
              <RefreshIcon className="animate-spin" width={16} />
            )}

            {!loading && !refreshQueued && <RefreshIcon width={16} />}
          </button>
        </div>

        {error && <div>Error!</div>}

        {!error && (
          <div className={refreshQueued || loading ? 'opacity-25' : ''}>
            {preview.map(line => (
              <SidebarPreviewLine
                key={line.slug}
                config={line}
                value={(data ?? {})[line.slug]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
