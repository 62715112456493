import type { ChangeEvent, DragEvent } from 'react';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckIcon } from '../../../icons/check.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash-2.svg';
import { ReactComponent as XIcon } from '../../../icons/x.svg';
import { getAPIClient } from '../../utils/getAPIClient';
import { ErrorAlert, SuccessAlert } from '../Alerts';

import { ImageDetails } from './shared/ImageDetails';
import { ImageGridWrapper } from './shared/ImageGridWrapper';

type ImageUploadScreenProps = Readonly<{
  group: string;

  onFinish(): void;
}>;

export function ImageUploadScreen({
  group,
  onFinish,
}: ImageUploadScreenProps): JSX.Element {
  const [dragActive, setDragActive] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const [selection, setSelection] = useState<number[]>([]);
  const [allFilesSelected, setAllFilesSelected] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [uploaded, setUploaded] = useState(false);
  const [failedUploads, setFailedUploads] = useState<string[]>([]);

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (dragActive) {
      setDragActive(false);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!dragActive) {
      setDragActive(true);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setDragActive(false);

    const droppedFiles: File[] = [];
    if (event.dataTransfer.items) {
      const { items } = event.dataTransfer;

      for (let a = 0; a < items.length; a += 1) {
        const item = items[a];

        if (item.kind === 'file') {
          const file = item.getAsFile();

          if (file?.type.startsWith('image/')) {
            droppedFiles.push(file);
          }
        }
      }
    }

    setFiles(droppedFiles);
  };

  const triggerFileInput = () => {
    document.getElementById('file-selector')?.click();
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const selectedFiles: File[] = [];
    if (target.files) {
      for (let a = 0; a < target.files.length; a += 1) {
        const file = target.files[a];

        if (file?.type.startsWith('image/')) {
          selectedFiles.push(file);
        }
      }
    }

    setFiles(selectedFiles);

    target.value = '';
  };

  const toggleSelectAll = () => {
    setSelection(allFilesSelected ? [] : files.map((_, index) => index));
  };

  const toggleSelectedFile = (fileIndex: number) => {
    const set = new Set(selection);

    if (set.has(fileIndex)) {
      set.delete(fileIndex);
    } else {
      set.add(fileIndex);
    }

    setSelection(Array.from(set));
  };

  const removeSelectedFiles = () => {
    setFiles(files.filter((_, index) => !selection.includes(index)));
  };

  const clearFiles = () => {
    setFiles([]);

    setUploaded(false);
    setFailedUploads([]);
  };

  const uploadFiles = async () => {
    const formData = new FormData();

    files.forEach(file => formData.append('file', file));

    setSubmitting(true);
    const response = await getAPIClient().put<{ failed: string[] }>(
      `/ui/image-groups/${group}/images`,
      formData
    );

    setSubmitting(false);

    setUploaded(true);
    setFailedUploads(response.data.failed);
  };

  useEffect(() => {
    setAllFilesSelected(files.length === selection.length);
  }, [files, selection]);

  useEffect(() => {
    setSelection([]);
  }, [files]);

  if (!files.length) {
    return (
      <div
        className="border border-primary border-dashed rounded-3xl p-4 flex flex-col items-center justify-center min-h-60"
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {dragActive && <div>Drop Here</div>}

        {!dragActive && !files.length && (
          <>
            <span>Drop & Drop</span>

            <div className="divider">OR</div>

            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={triggerFileInput}
            >
              Select Images
            </button>

            <input
              type="file"
              id="file-selector"
              className="hidden"
              multiple
              onChange={handleFileInputChange}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <button
          type="button"
          className="btn btn-sm btn-neutral btn-square"
          aria-label="Select All"
          disabled={submitting || uploaded}
          onClick={toggleSelectAll}
        >
          <input
            type="checkbox"
            checked={allFilesSelected}
            disabled={submitting || uploaded}
            className="checkbox checkbox-sm checkbox-primary"
            readOnly
          />
        </button>

        <button
          type="button"
          className="btn btn-sm btn-error"
          disabled={selection.length === 0 || submitting || uploaded}
          onClick={removeSelectedFiles}
        >
          <TrashIcon width={16} />
          Remove
        </button>
      </div>

      <ImageGridWrapper className="w-full min-h-52">
        {files.map((file, index) => (
          <ImageDetails
            key={`file-${file.name}`}
            name={file.name}
            url={URL.createObjectURL(file)}
            type={file.type.replace(/^image\//, '').replace(/\+.*$/, '')}
            size={file.size}
            selected={selection.includes(index)}
            onSelect={
              submitting || uploaded
                ? undefined
                : () => toggleSelectedFile(index)
            }
          />
        ))}
      </ImageGridWrapper>

      {!uploaded && (
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="btn btn-sm btn-error"
            disabled={selection.length > 0 || submitting}
            onClick={clearFiles}
          >
            <XIcon width={16} />
            Clear
          </button>

          <button
            type="button"
            className="btn btn-sm btn-primary"
            disabled={selection.length > 0 || submitting}
            onClick={uploadFiles}
          >
            <CheckIcon width={16} />
            Upload {files.length} File{files.length > 1 ? 's' : ''}
          </button>
        </div>
      )}

      {uploaded && (
        <>
          {failedUploads.length > 0 ? (
            <ErrorAlert
              message={`${failedUploads.length} file${
                failedUploads.length > 1 ? 's' : ''
              } failed to upload: ${failedUploads.join(', ')}`}
            />
          ) : (
            <SuccessAlert
              message={`${files.length} file${
                files.length > 1 ? 's' : ''
              } uploaded!`}
            />
          )}

          <div className="flex justify-end gap-4">
            <button
              type="button"
              className="btn btn-sm btn-neutral"
              onClick={clearFiles}
            >
              <PlusIcon width={16} />
              Upload More
            </button>

            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onFinish}
            >
              <CheckIcon width={16} />
              Done
            </button>
          </div>
        </>
      )}
    </div>
  );
}
