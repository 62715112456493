import React from 'react';

import type { FormFieldConfig } from '../../../types/Model';

import { ImageField } from './fields/ImageField';
import { NumberField } from './fields/NumberField';
import { SelectField } from './fields/SelectField';
import { TextField } from './fields/TextField';

export type FieldFactoryProps = Readonly<{
  config: FormFieldConfig;

  internal?: boolean;
}>;

export function FieldFactory(props: FieldFactoryProps): JSX.Element {
  const { config, internal } = props;

  switch (config.type) {
    // case 'datetime':
    //   return (
    //     <div className="w-full flex flex-wrap gap-2">
    //       <Join className="">
    //         <div className="join-item w-10 text-center bg-base-200 text-gray-400 border input-bordered flex items-center justify-center">
    //           <CalendarIcon width={16} />
    //         </div>
    //         <Input
    //           className="join-item w-16 px-0 text-center"
    //           placeholder="YYYY"
    //           size="sm"
    //           type="number"
    //           min={2000}
    //           max={2999}
    //         />
    //         <Input
    //           className="join-item w-12 px-0 text-center"
    //           placeholder="MM"
    //           size="sm"
    //           type="number"
    //           min={1}
    //           max={12}
    //         />
    //         <Input
    //           className="join-item w-12 px-0 text-center"
    //           placeholder="DD"
    //           size="sm"
    //           type="number"
    //           min={1}
    //           max={31}
    //         />
    //       </Join>
    //       <Join className="">
    //         <div className="join-item w-10 text-center bg-base-200 text-gray-400 border input-bordered flex items-center justify-center">
    //           <ClockIcon width={16} />
    //         </div>
    //         <Input
    //           className="join-item w-12 px-0 text-center"
    //           placeholder="HH"
    //           size="sm"
    //           type="number"
    //           min={0}
    //           max={23}
    //         />
    //         <Input
    //           className="join-item w-12 px-0 text-center"
    //           placeholder="MM"
    //           size="sm"
    //           type="number"
    //           min={0}
    //           max={59}
    //         />
    //       </Join>
    //     </div>
    //   );
    // case 'media':
    //   return (
    //     <div className="w-full flex flex-wrap gap-2">
    //       Media selector goes here
    //     </div>
    //   );

    case 'image':
      return <ImageField config={config} />;

    case 'number':
      return <NumberField config={config} internal={internal} />;

    case 'select':
      return <SelectField config={config} internal={internal} />;

    case 'string':
      return <TextField config={config} internal={internal} />;

    // case 'uid':
    //   return <Input className="w-full" size="sm" readOnly />;

    default:
      // eslint-disable-next-line no-console
      console.error('Unsupported medium field!', config satisfies never);
      throw new Error('Unsupported medium field!');
  }
}
