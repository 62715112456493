import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';

import { ReactComponent as GoogleIcon } from '../../../icons/google.svg';
import type { User } from '../../../types/User';
import { ErrorAlert } from '../../components/Alerts';
import { Logo } from '../../components/Logo';
import { getAPIClient } from '../../utils/getAPIClient';

type AccountLoginPageProps = {
  setUser(user: User): void;
};

export function AccountLoginPage({ setUser }: AccountLoginPageProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',

    onError(errorResponse) {
      // eslint-disable-next-line no-console
      console.error('Google Login Error', errorResponse);
      setErrorMessage('Failed to Login');

      setLoading(false);
    },

    onNonOAuthError(nonOAuthError) {
      if (nonOAuthError.type === 'popup_closed') {
        setErrorMessage('Popup window closed');
      } else {
        // eslint-disable-next-line no-console
        console.error('Auth Error', nonOAuthError);
        setErrorMessage('Failed to Login');
      }

      setLoading(false);
    },

    async onSuccess(tokenResponse) {
      try {
        const { data } = await getAPIClient().post<User>('/ui/login', {
          code: tokenResponse.code,
        });

        setUser(data);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Backend Error', error);
        setErrorMessage(error.message);
      }

      setLoading(false);
    },
  });

  const login = async () => {
    setLoading(true);
    setErrorMessage('');

    await googleLogin();
  };

  return (
    <div className="hero min-h-dvh p-4">
      <div className="card card-compact w-full max-w-sm bg-base-200 shadow-sm overflow-hidden">
        <div className="p-4 bg-[#4de898]">
          <Logo />
        </div>

        <div className="card-body gap-4">
          {errorMessage && <ErrorAlert message={errorMessage} />}

          <button
            className="btn btn-neutral join flex gap-4 items-center"
            type="button"
            onClick={login}
            disabled={loading}
          >
            <GoogleIcon className="join-item" width={20} />

            <span className="flex-grow join-item text-left">
              Sign in with Google
            </span>

            {loading && (
              <span className="join-item loading loading-spinner loading-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
