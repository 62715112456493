import React, { useEffect, useState } from 'react';

import { ReactComponent as TrashIcon } from '../../../icons/trash-2.svg';
import type { Image } from '../../../types/Images';
import { getAPIClient } from '../../utils/getAPIClient';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';
import { ErrorAlert, InfoAlert } from '../Alerts';
import { LoadingSpinner } from '../LoadingSpinner';

import { ImageDetails } from './shared/ImageDetails';
import { ImageGridWrapper } from './shared/ImageGridWrapper';

type ImageListScreenProps = Readonly<{
  group: string;
}>;

export function ImageListScreen({ group }: ImageListScreenProps): JSX.Element {
  const { loading, data, error, reload } = useAdminAPIGet<Image[]>(
    `/ui/image-groups/${group}/images`
  );

  const [selection, setSelection] = useState<number[]>([]);
  const [allFilesSelected, setAllFilesSelected] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const toggleSelectAll = () => {
    setSelection(allFilesSelected ? [] : data!.map((_, index) => index));
  };

  const toggleSelectedFile = (fileIndex: number) => {
    const set = new Set(selection);

    if (set.has(fileIndex)) {
      set.delete(fileIndex);
    } else {
      set.add(fileIndex);
    }

    setSelection(Array.from(set));
  };

  const deleteSelectedFiles = async () => {
    setSubmitting(true);

    await Promise.all(
      data!
        .filter((_, index) => selection.includes(index))
        .map(image => getAPIClient().delete(`/ui/images/${image.id}`))
    );

    await reload();

    setSubmitting(false);
  };

  useEffect(() => {
    setAllFilesSelected(data?.length === selection.length);
  }, [data, selection]);

  useEffect(() => {
    setSelection([]);
  }, [data]);

  if (loading) {
    return (
      <div className="h-16">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return <ErrorAlert message="Failed to load images" />;
  }

  if (!data?.length) {
    return (
      <InfoAlert>
        <span className="text-base">
          This group has no images - you can upload some on the
          <span className="font-bold"> Upload </span>tab.
        </span>
      </InfoAlert>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <button
          type="button"
          className="btn btn-sm btn-neutral btn-square"
          aria-label="Select All"
          disabled={submitting}
          onClick={toggleSelectAll}
        >
          <input
            type="checkbox"
            checked={allFilesSelected}
            disabled={submitting}
            className="checkbox checkbox-sm checkbox-primary"
            readOnly
          />
        </button>

        <button
          type="button"
          className="btn btn-sm btn-error"
          disabled={selection.length === 0 || submitting}
          onClick={deleteSelectedFiles}
        >
          <TrashIcon width={16} />
          Delete
        </button>
      </div>

      <ImageGridWrapper className="grow">
        {data?.map((image, index) => (
          <ImageDetails
            key={image.slug}
            name={image.originalName}
            size={image.size}
            type={image.extension}
            url={image.url}
            selected={selection.includes(index)}
            onSelect={submitting ? undefined : () => toggleSelectedFile(index)}
          />
        ))}
      </ImageGridWrapper>
    </div>
  );
}
