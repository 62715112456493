import React from 'react';

import { ReactComponent as HashDashIcon } from '../../icons/hashdash.svg';

export function Logo(): JSX.Element {
  return (
    <div className="w-full flex gap-2 justify-center items-baseline pt-2">
      <HashDashIcon height={28} />

      <span className="text-[#1D3849] font-bold text-xl">Admin</span>
    </div>
  );
}
