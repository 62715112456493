import type { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import type { Option } from '../components/forms/fields/select/StaticOptionsSelect';

import { getAPIClient } from './getAPIClient';

export type OptionsLoaderHook = {
  error?: AxiosError;
  loading: boolean;

  getOption(path: string, id: number | string): Option | undefined;
};

export function useOptionsLoader(paths: string[]): OptionsLoaderHook {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<Record<string, Option[]>>();

  async function makeRequest() {
    setLoading(true);

    setError(undefined);
    setData(undefined);

    try {
      const result: Record<string, Option[]> = {};

      const client = getAPIClient();
      // eslint-disable-next-line no-restricted-syntax
      for (const path of paths) {
        // eslint-disable-next-line no-await-in-loop
        const response = await client.get(path);

        result[path] = response.data;
      }

      setData(result);
    } catch (e) {
      setError(e as AxiosError);
    }

    setLoading(false);
  }

  useEffect(() => {
    makeRequest();
  }, [JSON.stringify(paths)]);

  return {
    error,
    loading,

    getOption(path: string, id: number | string) {
      const options = (data ? data[path] : undefined) ?? [];

      return options.find(option => option.value === id);
    },
  };
}
