import type { ChangeEventHandler, ReactEventHandler } from 'react';
import React, { useState } from 'react';

import { ImageDetailsWrapper } from './ImageDetailsWrapper';

type ImageDetailsProps = Readonly<{
  name: string;
  url: string;
  type: string;
  size: number;

  selected?: boolean;

  onClick?: () => void;
  onSelect?: () => void;
}>;

function convertSize(size: number): string {
  if (size < 1024) return `${size}B`;

  const kSize = size / 1024;
  if (kSize < 1024) return `${Math.round(kSize)}KB`;

  const mSize = kSize / 1024;
  return `${Math.round(mSize)}MB`;
}

export function ImageDetails({
  name,
  url,
  type,
  size,
  selected,
  onClick,
  onSelect,
}: ImageDetailsProps): JSX.Element {
  const [imageDimensions, setImageDimensions] = useState<string>();

  const handleOnLoad: ReactEventHandler<HTMLImageElement> = event => {
    const { target } = event;
    if ('naturalHeight' in target && 'naturalWidth' in target) {
      setImageDimensions(`${target.naturalWidth}x${target.naturalHeight}`);
    }
  };

  const handleSelect: ChangeEventHandler = event => {
    event.stopPropagation();

    if (onSelect) {
      onSelect();
    }
  };

  let checkboxStyle = 'hidden';
  if (onSelect) {
    checkboxStyle = selected ? 'block' : 'hidden group-hover:block';
  }

  return (
    <ImageDetailsWrapper onClick={onClick}>
      <figure className="aspect-w-16 aspect-h-10 bg-slate-600 overflow-hidden">
        <div className="flex justify-center items-center">
          <img
            src={url}
            alt={name}
            onLoad={handleOnLoad}
            className="max-h-full max-w-full"
          />
        </div>

        <input
          type="checkbox"
          checked={selected}
          className={`checkbox checkbox-primary top-2 left-2 ${checkboxStyle}`}
          onClick={event => event.stopPropagation()}
          onChange={handleSelect}
        />
      </figure>

      <div className="card-body">
        <span className="overflow-hidden text-ellipsis truncate">{name}</span>
        <span className="text-xs">
          {type.toUpperCase()} - {imageDimensions} - {convertSize(size)}
        </span>
      </div>
    </ImageDetailsWrapper>
  );
}
