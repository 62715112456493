import React, { useState } from 'react';

import { ReactComponent as CheckIcon } from '../../../icons/check.svg';
import type { Image } from '../../../types/Images';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';
import { ErrorAlert, InfoAlert } from '../Alerts';
import { LoadingSpinner } from '../LoadingSpinner';

import { ImageDetails } from './shared/ImageDetails';
import { ImageGridWrapper } from './shared/ImageGridWrapper';

type ImagePickScreenProps = Readonly<{
  group: string;

  onSelect(selection: Image[]): void;
}>;

export function ImagePickScreen({
  group,
  onSelect,
}: ImagePickScreenProps): JSX.Element {
  const baseUrl = `/ui/image-groups${group ? `/${group}/images` : ''}`;
  const { loading, data, error } = useAdminAPIGet<Image[]>(baseUrl);

  const [selection, setSelection] = useState<number[]>([]);

  const toggleSelectedFile = (fileIndex: number) => {
    setSelection([fileIndex]);
  };

  const handleConfirmSelect = () => {
    onSelect((data ?? []).filter((_, index) => selection.includes(index)));
  };

  if (loading) {
    return (
      <div className="h-16">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return <ErrorAlert message="Failed to load images" />;
  }

  if (!data?.length) {
    return (
      <InfoAlert>
        <span className="text-base">
          This group has no images - you can upload some on the
          <span className="font-bold"> Upload </span>tab.
        </span>
      </InfoAlert>
    );
  }

  return (
    <div className="space-y-4">
      <ImageGridWrapper className="grow">
        {data?.map((image, index) => (
          <ImageDetails
            key={image.slug}
            name={image.originalName}
            size={image.size}
            type={image.extension}
            url={image.url}
            selected={selection.includes(index)}
            onSelect={() => toggleSelectedFile(index)}
            onClick={() => toggleSelectedFile(index)}
          />
        ))}
      </ImageGridWrapper>

      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          disabled={selection.length === 0}
          onClick={handleConfirmSelect}
        >
          <CheckIcon width={16} />
          Select
        </button>
      </div>
    </div>
  );
}
