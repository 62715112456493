import React from 'react';
import { NavLink } from 'react-router-dom';

import type {
  FormChildrenLinksConfig,
  FormPreviewConfig,
} from '../../../types/Model';
import { SidebarPreviewCard } from '../../components/forms/SidebarPreviewCard';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';

type FormSidebarLinkProps = Readonly<{
  id: string;

  link: NonNullable<FormChildrenLinksConfig>[number];
}>;

function FormSidebarLink({ id, link }: FormSidebarLinkProps): JSX.Element {
  const { loading, error, data } = useAdminAPIGet<{ count: number }>(
    `/${link.slug}/count?${link.field}=${id}`
  );

  const count = data?.count ?? 0;
  const label = count !== 1 ? 'Items' : 'Item';

  return (
    <div className="flex">
      <p>{link.title}</p>

      <NavLink
        className="link link-primary no-underline"
        to={`/${link.slug}?${link.field}=${id}`}
      >
        {loading ? 'View' : `${count} ${label}`}
        {error && <span> ⚠️</span>}
      </NavLink>
    </div>
  );
}

type FormSidebarProps = Readonly<{
  baseUrl: string;
  id?: string;

  formValues: Record<string, unknown>;

  childrenLinks: FormChildrenLinksConfig;
  preview: FormPreviewConfig;
}>;

export function FormSidebar(props: FormSidebarProps): JSX.Element {
  const { baseUrl, id, formValues, childrenLinks, preview } = props;

  return (
    <div className="space-y-4">
      {id && childrenLinks && (
        <div className="card card-bordered card-compact bg-base-100 shadow-sm">
          <div className="card-body space-y-2">
            <span className="font-bold">Links</span>

            {childrenLinks.map(link => (
              <FormSidebarLink key={link.slug} id={id} link={link} />
            ))}
          </div>
        </div>
      )}
      {preview && (
        <SidebarPreviewCard
          baseUrl={baseUrl}
          formValues={formValues}
          preview={preview}
        />
      )}
    </div>
  );
}
