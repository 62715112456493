import type { PropsWithChildren } from 'react';
import React from 'react';

import { LayoutDrawer } from './layout/Drawer';
import { LayoutNavbar } from './layout/Navbar';

type LayoutProps = PropsWithChildren & {
  title: string;
};

export function Layout({ children, title }: LayoutProps) {
  return (
    <LayoutDrawer>
      <LayoutNavbar title={title} />
      {children}
    </LayoutDrawer>
  );
}
