import React from 'react';

type LoadingSpinnerProps = Readonly<{
  fullHeight?: boolean;
}>;

export function LoadingSpinner({
  fullHeight,
}: LoadingSpinnerProps): JSX.Element {
  return (
    <div className={`hero ${fullHeight ? 'min-h-dvh' : 'h-full'}`}>
      <span className="loading loading-spinner" />
    </div>
  );
}
