import React, { useEffect, useState } from 'react';

import { ReactComponent as FolderIcon } from '../../../icons/folder.svg';
import { ReactComponent as ImageIcon } from '../../../icons/image.svg';
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg';
import { useImageGroups } from '../../utils/useImageGroups';

export type ImageMode = 'groups' | 'images' | 'upload';

type ImageModeTabsProps = Readonly<{
  group?: string;

  groupSelection?: true;

  mode: ImageMode;
  onSelect(mode: ImageMode): void;
}>;

export function ImageModeTabs({
  group,

  groupSelection,

  mode,
  onSelect,
}: ImageModeTabsProps): JSX.Element {
  const { loading, getGroupLabel } = useImageGroups();

  const [groupLabel, setGroupLabel] = useState('');

  useEffect(() => {
    if (group) {
      setGroupLabel(getGroupLabel(group));
    } else {
      setGroupLabel('');
    }
  }, [group, loading]);

  const activeTab = 'tab tab-active';
  const inactiveTab = 'tab';

  const showGroups = () => onSelect('groups');
  const showImages = () => onSelect('images');
  const showUploads = () => onSelect('upload');

  return (
    <div role="tablist" className="tabs tabs-bordered">
      {groupSelection && (
        <button
          type="button"
          role="tab"
          className={mode === 'groups' ? activeTab : inactiveTab}
          onClick={showGroups}
        >
          <FolderIcon width={16} className="mr-2 hidden sm:block" />
          Group
        </button>
      )}

      <button
        type="button"
        role="tab"
        disabled={!group}
        className={mode === 'images' ? activeTab : inactiveTab}
        onClick={showImages}
      >
        <ImageIcon width={16} className="mr-2 hidden sm:block" />
        {groupLabel ? `${groupLabel} ` : ''}Images
      </button>

      <button
        type="button"
        role="tab"
        disabled={!group}
        className={mode === 'upload' ? activeTab : inactiveTab}
        onClick={showUploads}
      >
        <UploadIcon width={16} className="mr-2 hidden sm:block" />
        Upload{groupLabel ? ` to ${groupLabel}` : ''}
      </button>
    </div>
  );
}
