import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import type { Model } from '../../types/Model';
import { ModelsContext } from '../context/models';
import { Layout } from '../Layout';
import { OldApp } from '../OldApp';
import { DeletePage } from '../pages/deletePage';
import { FormPage } from '../pages/formPage';
import { ImageManagerPage } from '../pages/imageManagerPage';
import { ListPage } from '../pages/listPage';
import { useAdminAPIGet } from '../utils/useAdminAPIGet';

import { LoadingSpinner } from './LoadingSpinner';

function parseModel(model: Model): RouteObject[] {
  return [
    {
      path: `/${model.slug}`,
      element: (
        <Layout title={model.name}>
          <ListPage model={model} />
        </Layout>
      ),
    },
    {
      path: `/${model.slug}/create`,
      element: (
        <Layout title={`${model.name} / Create`}>
          <FormPage model={model} />
        </Layout>
      ),
    },
    {
      path: `/${model.slug}/copy/:id`,
      element: (
        <Layout title={`${model.name} / Copy`}>
          <FormPage model={model} copyMode />
        </Layout>
      ),
    },
    {
      path: `/${model.slug}/delete/:id`,
      element: (
        <Layout title={`${model.name} / Delete`}>
          <DeletePage model={model} />
        </Layout>
      ),
    },
    {
      path: `/${model.slug}/edit/:id`,
      element: (
        <Layout title={`${model.name} / Edit`}>
          <FormPage model={model} editMode />
        </Layout>
      ),
    },
  ];
}

function buildRoutes(models: Model[]): RouteObject[] {
  return [
    {
      path: '/',
      element: (
        <Layout title="Home">
          <OldApp />
        </Layout>
      ),
    },

    {
      path: '/image-manager',
      element: (
        <Layout title="Image Manager">
          <ImageManagerPage />
        </Layout>
      ),
    },

    {
      path: '/image-manager/:group',
      element: (
        <Layout title="Image Manager">
          <ImageManagerPage />
        </Layout>
      ),
    },

    ...models.flatMap(parseModel),
  ];
}

export function Router(): JSX.Element {
  const { data, loading } = useAdminAPIGet<Model[]>('/ui/models');

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  if (!data?.length) {
    return <div>Error!</div>;
  }

  const router = createBrowserRouter(buildRoutes(data));

  return (
    <ModelsContext.Provider value={data}>
      <RouterProvider router={router} />
    </ModelsContext.Provider>
  );
}
