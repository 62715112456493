import type { ISchema, ObjectSchema, ObjectShape } from 'yup';
import { object, string } from 'yup';

import type { FormFieldConfig } from '../../../types/Model';

type ParseFieldsResult<T extends Record<string, any>> = {
  values: T;
  schema: ObjectSchema<T>;
};

function buildObjectShape(config: FormFieldConfig): ISchema<any> {
  let shape = string();

  if (config.optional !== true) {
    shape = shape.required('Required');
  }

  return shape;
}

export function parseFields<T extends Record<string, any>>(
  configs: FormFieldConfig[],
  data: Record<string, string> = {}
): ParseFieldsResult<T> {
  const values: Record<string, any> = {};
  const schemaShape: ObjectShape = {};

  configs.forEach(config => {
    values[config.key] = data[config.key] ?? '';

    schemaShape[config.key] = buildObjectShape(config);
  });

  return {
    values: values as T,
    schema: object().shape(schemaShape) as ObjectSchema<T>,
  };
}
