import type { PropsWithChildren } from 'react';
import React from 'react';

import { ReactComponent as CheckIcon } from '../../icons/check-circle.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as XIcon } from '../../icons/x-circle.svg';

type AlertProps = PropsWithChildren &
  Readonly<{
    message?: string;
  }>;

export function ErrorAlert({ children, message }: AlertProps): JSX.Element {
  return (
    <div role="alert" className="alert alert-error">
      <XIcon width={24} />

      {children ?? <span className="text-base">{message}</span>}
    </div>
  );
}

export function InfoAlert({ children, message }: AlertProps): JSX.Element {
  return (
    <div role="alert" className="alert alert-info">
      <InfoIcon width={24} />

      {children ?? <span className="text-base">{message}</span>}
    </div>
  );
}

export function SuccessAlert({ children, message }: AlertProps): JSX.Element {
  return (
    <div role="alert" className="alert alert-success">
      <CheckIcon width={24} />

      {children ?? <span className="text-base">{message}</span>}
    </div>
  );
}
