import React from 'react';

import { useImageGroups } from '../../utils/useImageGroups';
import { ErrorAlert } from '../Alerts';
import { LoadingSpinner } from '../LoadingSpinner';

type ImageGroupsScreenProps = Readonly<{
  group?: string;

  onSelect: (value: string) => void;
}>;

export function ImageGroupsScreen({
  group,
  onSelect,
}: ImageGroupsScreenProps): JSX.Element {
  const { loading, error, groups } = useImageGroups();

  if (loading) {
    return (
      <div className="h-16">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return <ErrorAlert message="Failed to load image groups" />;
  }

  return (
    <div className="grid gap-4 grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {groups?.map(g => {
        const selectionClass = g.slug === group ? 'btn-primary' : '';

        return (
          <button
            key={g.id}
            type="button"
            className={`btn btn-outline ${selectionClass}`}
            onClick={() => onSelect(g.slug)}
          >
            {g.label}
          </button>
        );
      })}
    </div>
  );
}
