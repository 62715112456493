import React from 'react';

import type { FieldFactoryProps } from './FieldFactory';
import { FieldFactory } from './FieldFactory';
import { FormFieldWrapper } from './FormFieldWrapper';

type FormFieldProps = FieldFactoryProps;

export function FormField({ config, internal }: FormFieldProps): JSX.Element {
  return (
    <FormFieldWrapper config={config}>
      <FieldFactory config={config} internal={internal} />
    </FormFieldWrapper>
  );
}
