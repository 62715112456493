import React from 'react';

import type { FormPreviewLineConfig } from '../../../types/Model';

type SidebarPreviewLineProps = Readonly<{
  config: FormPreviewLineConfig;
  value: unknown;
}>;

function formatValue(
  value: unknown,
  format: FormPreviewLineConfig['format']
): string {
  switch (format) {
    case 'money':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(value ?? 0));

    default:
      throw new Error(`Unsupported format: ${format}`);
  }
}

export function SidebarPreviewLine({
  config,
  value,
}: SidebarPreviewLineProps): JSX.Element {
  return (
    <div className="flex justify-between">
      <span>{config.label}</span>
      <span>{formatValue(value, config.format)}</span>
    </div>
  );
}
