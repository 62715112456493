import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ImageIcon } from '../../../../icons/image.svg';
import type { Image } from '../../../../types/Images';
import type { ImageFormFieldConfig } from '../../../../types/Model';
import { useAdminAPIGet } from '../../../utils/useAdminAPIGet';
import { ImagePicker } from '../../Images/ImagePicker';

type ImageFieldProps = Readonly<{
  config: ImageFormFieldConfig;
}>;

export function ImageField({ config }: ImageFieldProps) {
  const { key, group } = config;

  const formik = useFormikContext<Record<string, unknown>>();

  const [image, setImage] = useState<Image>();

  const { data } = useAdminAPIGet<Image>(`/ui/images/${formik.values[key]}`);

  useEffect(() => {
    formik.setFieldValue(key, image?.id ?? formik.values[key]);
  }, [image]);

  useEffect(() => {
    setImage(data);
  }, [data]);

  const onSelect = (selection: Image[]) => {
    setImage(selection[0]);
  };

  return (
    <div className="join join-vertical">
      <div className="aspect-w-16 aspect-h-10 bg-slate-600 join-item cursor-pointer overflow-hidden">
        {image && (
          <div className="flex justify-center items-center">
            <img
              src={image.url}
              alt={image.originalName}
              className="max-h-full max-w-full"
            />
          </div>
        )}

        {!image && (
          <div className="flex items-center justify-center max-h-full max-w-full">
            <ImageIcon height={64} width={64} strokeWidth={0.5} />
          </div>
        )}
      </div>
      <div className="join-item">
        <ImagePicker group={group} onSelect={onSelect} />
      </div>
    </div>
  );
}
