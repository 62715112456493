import { Field } from 'formik';
import React from 'react';

import type { NumberFormFieldConfig } from '../../../../types/Model';

type NumberFieldProps = Readonly<{
  config: NumberFormFieldConfig;

  internal?: boolean;
}>;

export function NumberField({ config, internal }: NumberFieldProps) {
  const { key, decimals, blockEdit } = config;

  const editBlocked =
    blockEdit === true || (blockEdit === 'if-internal' && internal);

  return (
    <Field
      type="number"
      name={key}
      disabled={editBlocked}
      step={1 / 10 ** (decimals ?? 0)}
      className="input input-bordered input-sm w-full"
    />
  );
}
