import type { KeyboardEventHandler, PropsWithChildren } from 'react';
import React from 'react';

type ImageDetailsWrapperProps = Readonly<{
  onClick?: () => void;
}>;

export function ImageDetailsWrapper({
  onClick,
  children,
}: PropsWithChildren & ImageDetailsWrapperProps): JSX.Element {
  const onKeyDown: KeyboardEventHandler = event => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };

  const className = 'card card-compact bg-base-100 group';

  if (onClick) {
    return (
      <div
        role="button"
        tabIndex={0}
        className={className}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {children}
      </div>
    );
  }

  return <div className={className}>{children}</div>;
}
