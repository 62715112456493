import { Field } from 'formik';
import React from 'react';

import type { StringFormFieldConfig } from '../../../../types/Model';

type TextFieldProps = Readonly<{
  config: StringFormFieldConfig;

  internal?: boolean;
}>;

export function TextField({ config, internal }: TextFieldProps) {
  const { key, blockEdit } = config;

  const editBlocked =
    blockEdit === true || (blockEdit === 'if-internal' && internal);

  return (
    <Field
      name={key}
      disabled={editBlocked}
      className="input input-bordered input-sm w-full"
    />
  );
}
