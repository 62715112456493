import React, { useRef, useState } from 'react';

import type { Image } from '../../../types/Images';

import { ImageModeTabs, type ImageMode } from './ImageModeTabs';
import { ImagePickScreen } from './ImagePickScreen';
import { ImageUploadScreen } from './ImageUploadScreen';

type ImagePickerProps = Readonly<{
  group: string;

  onSelect(selection: Image[]): void;
}>;

export function ImagePicker({
  group,
  onSelect,
}: ImagePickerProps): JSX.Element {
  const ref = useRef<HTMLDialogElement | null>(null);

  const [mode, setMode] = useState<ImageMode>('images');

  const hide = () => ref.current?.close();
  const show = () => ref.current?.showModal();

  const handleOnSelect = (selection: Image[]) => {
    onSelect(selection);
    hide();
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-primary btn-block join-item"
        onClick={show}
      >
        Select
      </button>
      <dialog
        ref={ref}
        onCancel={hide}
        className="modal modal-bottom sm:modal-middle"
      >
        <div className="modal-box sm:w-11/12 sm:max-w-5xl">
          <button
            type="button"
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            onClick={hide}
          >
            ✕
          </button>
          <h3 className="text-lg font-bold">Image Picker</h3>
          <section className="p-4 space-y-4">
            <ImageModeTabs group={group} mode={mode} onSelect={setMode} />

            {mode === 'images' && group && (
              <ImagePickScreen group={group} onSelect={handleOnSelect} />
            )}

            {mode === 'upload' && (
              <ImageUploadScreen
                group={group}
                onFinish={() => setMode('images')}
              />
            )}
          </section>
        </div>
      </dialog>
    </>
  );
}
