import { Field } from 'formik';
import React from 'react';

export type Option = {
  label: string;
  value: number | string;
};

type StaticOptionsSelectProps = Readonly<{
  name: string;

  options: Option[];

  disabled?: boolean;
}>;

export function StaticOptionsSelect({
  name,
  options,
  disabled,
}: StaticOptionsSelectProps): JSX.Element {
  return (
    <Field
      name={name}
      disabled={disabled}
      component="select"
      className="select select-bordered select-sm"
    >
      <option disabled hidden>
        {}
      </option>

      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Field>
  );
}
