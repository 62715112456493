import React from 'react';
import { Button, Table } from 'react-daisyui';
import { NavLink, useSearchParams } from 'react-router-dom';

import { ReactComponent as CopyIcon } from '../../../icons/copy.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash-2.svg';
import type {
  ListColumnConfig,
  ListLinksConfig,
  Model,
} from '../../../types/Model';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import type { GenericCollection } from '../../utils/useAdminAPIGet';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';
import type { OptionsLoaderHook } from '../../utils/useOptionsLoader';
import { useOptionsLoader } from '../../utils/useOptionsLoader';

type ListRowActionsProps = Readonly<{
  basePath: string;

  blockCreate?: true;
  blockDelete?: true | 'if-internal';

  data: Record<string, number | string>;
}>;

function ListRowActions({
  basePath,
  blockCreate,
  blockDelete,
  data,
}: ListRowActionsProps): JSX.Element {
  const { id, internal } = data;

  const deleteBlocked =
    blockDelete === true || (blockDelete === 'if-internal' && internal);

  return (
    <div className="flex gap-2 justify-end">
      <NavLink to={`${basePath}/edit/${id}`} className="link link-primary">
        <EditIcon width={14} />
      </NavLink>

      {blockCreate ? (
        <CopyIcon className="opacity-50" width={14} />
      ) : (
        <NavLink to={`${basePath}/copy/${id}`} className="link link-primary">
          <CopyIcon width={14} />
        </NavLink>
      )}

      {deleteBlocked ? (
        <TrashIcon className="opacity-50" width={14} />
      ) : (
        <NavLink to={`${basePath}/delete/${id}`} className="link link-primary">
          <TrashIcon width={14} />
        </NavLink>
      )}
    </div>
  );
}

type ListRowProps = Readonly<{
  basePath: string;

  blockCreate?: true;
  blockDelete?: true | 'if-internal';

  columns: ListColumnConfig[];
  links: ListLinksConfig;

  data: Record<string, number | string>;

  optionsLoaderHook: OptionsLoaderHook;
}>;

function ListRow({
  basePath,
  blockCreate,
  blockDelete,
  columns,
  links = {},
  data,
  optionsLoaderHook,
}: ListRowProps): JSX.Element {
  const { id } = data;

  const rowColumns = [
    ...columns.map(config => {
      const key = `${config.key}.${id}`;

      if (config.key in links) {
        if (optionsLoaderHook.loading) {
          return (
            <div key={key} className="flex gap-2">
              <span className="loading loading-spinner loading-xs" />

              <span>{data[config.key]}</span>
            </div>
          );
        }

        const option = optionsLoaderHook.getOption(
          `/${links[config.key]}/options`,
          data[config.key]
        );

        return (
          <NavLink
            className="link link-primary no-underline"
            key={key}
            to={`/${links[config.key]}/edit/${data[config.key]}`}
          >
            {option?.label} ({data[config.key]})
          </NavLink>
        );
      }

      return <span key={key}>{data[config.key]}</span>;
    }),

    <ListRowActions
      key={`actions.${id}`}
      basePath={basePath}
      blockCreate={blockCreate}
      blockDelete={blockDelete}
      data={data}
    />,
  ];

  return <Table.Row>{rowColumns}</Table.Row>;
}

type ListPageProps = Readonly<{
  model: Model;
}>;

export function ListPage({ model }: ListPageProps) {
  const { columns, blockCreate, blockDelete, links } = model.list;

  const basePath = `/${model.slug}`;

  const [searchParams] = useSearchParams();
  const { data, loading } = useAdminAPIGet<GenericCollection>(
    `${basePath}?${searchParams.toString()}`
  );

  const optionsLoaderHook = useOptionsLoader(
    Object.values(links ?? {}).map(slug => `/${slug}/options`)
  );

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  return (
    <section className="p-4 space-y-4">
      <div className="flex">
        <div className="flex-grow" />

        <div>
          {blockCreate ? (
            <Button color="primary" size="sm" disabled>
              <PlusIcon width={16} />
              Create
            </Button>
          ) : (
            <NavLink to={`${basePath}/create`}>
              <Button color="primary" size="sm">
                <PlusIcon width={16} />
                Create
              </Button>
            </NavLink>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <Table zebra>
          <Table.Head>
            {columns.map(column => (
              <span key={column.key}>{column.label}</span>
            ))}
          </Table.Head>

          <Table.Body>
            {data.map(row => (
              <ListRow
                key={row.id}
                basePath={basePath}
                blockCreate={blockCreate}
                blockDelete={blockDelete}
                columns={columns}
                links={links}
                data={row}
                optionsLoaderHook={optionsLoaderHook}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    </section>
  );
}
