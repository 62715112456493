import type { AxiosInstance } from 'axios';
import axios from 'axios';

let client: AxiosInstance;
export function getAPIClient(): AxiosInstance {
  if (!client) {
    client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,

      withCredentials: true,
    });
  }

  return client;
}
