/* eslint-disable react/jsx-props-no-spreading */
import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Card } from 'react-daisyui';
import { useNavigate, useParams } from 'react-router-dom';

import type { Model } from '../../../types/Model';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { getAPIClient } from '../../utils/getAPIClient';
import type { GenericEntity } from '../../utils/useAdminAPIGet';
import { useAdminAPIGet } from '../../utils/useAdminAPIGet';

import { Form } from './Form';
import { FormSidebar } from './FormSidebar';
import { parseFields } from './parseFields';

type FormPageProps = Readonly<{
  copyMode?: boolean;
  editMode?: boolean;

  model: Model;
}>;

type SubmitFn<T extends Record<string, any>> = (
  payload: T,
  helper: FormikHelpers<T>
) => void;

type SubmitFnOptions<T extends Record<string, any>> = {
  submitFn: (payload: T) => Promise<void>;
};

function getSubmitFn<T extends Record<string, any>>({
  submitFn,
}: SubmitFnOptions<T>): SubmitFn<T> {
  return async (payload, { setErrors }) => {
    try {
      await submitFn(payload);
    } catch (error: any) {
      if ('isAxiosError' in error && error.isAxiosError) {
        const { response } = error as AxiosError<{
          fieldErrors: Record<keyof T, any>;
        }>;

        if (response?.status === 400) {
          const errors = response.data?.fieldErrors;

          if (errors) {
            setErrors(errors);
            return;
          }
        }
      }

      throw error;
    }
  };
}

export function FormPage({ model, copyMode, editMode }: FormPageProps) {
  const { fields } = model.form;

  const { id } = useParams();

  const { data, loading } =
    copyMode || editMode
      ? useAdminAPIGet<GenericEntity>(`${model.slug}/${id}`)
      : { data: undefined, loading: false };

  const navigate = useNavigate();

  const { schema, values } = parseFields(fields, data);

  const [formValues, setFormValues] = useState({});

  const onSubmit = getSubmitFn({
    submitFn: editMode
      ? payload =>
          getAPIClient()
            .patch(`/${model.slug}/${id}`, payload)
            .then(() => navigate(`/${model.slug}`))
      : payload =>
          getAPIClient()
            .put(`/${model.slug}`, payload)
            .then(() => navigate(`/${model.slug}`)),
  });

  if (loading) {
    return <LoadingSpinner fullHeight />;
  }

  return (
    <section className="p-4 grid grid-cols-1 gap-4 md:grid-cols-3">
      <div className="col-span-2">
        <div>
          <Card className="bg-base-100 shadow-sm" compact>
            <Card.Body>
              <Form
                fields={fields}
                schema={schema}
                values={values}
                onChange={setFormValues}
                onSubmit={onSubmit}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      <FormSidebar
        baseUrl={model.slug}
        id={id}
        formValues={formValues}
        childrenLinks={model.form.childrenLinks}
        preview={model.form.preview}
      />
    </section>
  );
}
