import React from 'react';

import type { SelectFormFieldConfig } from '../../../../types/Model';

import { RemoteOptionsSelect } from './select/RemoteOptionsSelect';
import { StaticOptionsSelect } from './select/StaticOptionsSelect';

type SelectFieldProps = Readonly<{
  config: SelectFormFieldConfig;

  internal?: boolean;
}>;

export function SelectField({ config, internal }: SelectFieldProps) {
  const { key, blockEdit, options } = config;

  const editBlocked =
    blockEdit === true || (blockEdit === 'if-internal' && internal);

  return typeof options === 'string' ? (
    <RemoteOptionsSelect
      name={key}
      disabled={editBlocked}
      optionsUrl={options}
    />
  ) : (
    <StaticOptionsSelect name={key} disabled={editBlocked} options={options} />
  );
}
